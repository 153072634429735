import React from 'react';
import { Card } from 'react-bootstrap';
import './product.css'; // Ensure your CSS file is imported

const ProductCard = ({ title, text, icon, link }) => {
  return (
    <Card className="product-card text-center">
      <Card.Body>
        <div className="product-icon">{icon}</div>
        <Card.Title className="product-title">{title}</Card.Title>
        <Card.Text className="product-text fixed-height">
          {text}
        </Card.Text>
        {link && <Card.Link href={link.href} className="product-link">{link.text}</Card.Link>}
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
