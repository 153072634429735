// HeroSection.js
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './hero.css';
import heroImage from '../../image/imageHero.png';
import { Link } from 'react-router-dom'
const HeroSection = () => {
  return (
    <div className="hero-section">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="text-content">
              <p className="top-seller">TOP SELLER IN THE WEEK</p>
              <h1>Discover the Power of <br /> Nature! </h1>
              <p className="description">
              We specialize in competitive pricing, fast shipping, and consistent stock levels, ensuring that the ingredients you need are always available when you need them. From teas to essential oils, every product meets the highest safety and quality standards.
              </p>
              <Link to={`/product/66c9bde55afa654bf938225a`}>
              <Button variant="success" className="shop-now-btn">
                Shop Now
              </Button>
              </Link>
            </div>
          </Col>
          <Col md={6}>
            <img
              src={heroImage}
              alt="Defne Market"
              className="hero-image"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
