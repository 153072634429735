import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProductCard from './ProductCard';
import { HiCheckCircle } from 'react-icons/hi';
import ResponsiveImage from './Image';
import './product.css'; // Ensure your CSS file is imported

const ProductInfoGroup = () => {
  const icon = <HiCheckCircle />;

  return (
    <Container className="product-info-group">
      <div className="slogan-overlay-container">
        <ResponsiveImage
          src="https://www.herbco.com/media/homepages/base2021/images/herbs-and-spices.jpg"
          alt="Herbs and Spices"
        />
        <div className="slogan-overlay">
          <h1 className="slogan-text">Empowering Your Success with Defne Market – Where Quality Meets Competitive Advantage</h1>
        </div>
      </div>
      <Row className="mt-5">
        <Col md={4}>
          <ProductCard
            title="HIGH QUALITY STANDARDS"
            text="We realize this is table stakes. Our sourcing, quality and testing protocols meet industry standards and American Herbal Product Association standards. Try a sample and see for yourself."
            icon={icon}
          />
        </Col>
        <Col md={4}>
          <ProductCard
            title="STRONGER IN STOCK POSITION + FASTER SHIPPING"
            text="We pride ourselves on a high in stock level at all times across 600+ products. Orders usually ship in 2 business days from when placed. We ship from two locations, so orders arrive faster. All so you can run your business smoothly."
            icon={icon}
          />
        </Col>
        <Col md={4}>
          <ProductCard
            title="COMPETITIVE PRICING"
            text="Ingredients are a big part of your product cost. Our web price is already wholesale and usually lower than competitive wholesale prices. Free shipping kicks in at $250 and a 15% discount is added for $600+ order value."
            icon={icon}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ProductInfoGroup;
