import React from 'react';
import { Image } from 'react-bootstrap';

const ResponsiveImage = ({ src, alt }) => {
  return (
    <div className="responsive-image-wrapper">
      <Image src={src} alt={alt} fluid className="responsive-image" />
    </div>
  );
};

export default ResponsiveImage;
